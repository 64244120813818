// import React, { useEffect, useState } from 'react';

// const ListView = ({ dataList, lastEnteredTextIndex, dailyCount}) => {
  // const [currentIndex, setCurrentIndex] = useState(dailyCount);
  
  // const [currentIndex2, setcurrentIndex2] = useState(dailyCount2)

  // useEffect(() => {
    
    // const storedIndex = parseInt(localStorage.getItem('currentIndex'));
    // console.log("count",currentIndex);
    // const storedDate = localStorage.getItem('storedDate');
    
  
    // const currentDate = new Date().toLocaleDateString();
   
    // if (storedDate !== currentDate) {
     
      // setCurrentIndex("0");already cmd
      // setCurrentIndex(dailyCount);already cmd
      // localStorage.setItem('storedDate', currentDate);already cmd
      // setCurrentIndex(dataList.length > 0 ? dataList.length - 1 : 0);
      // localStorage.setItem('storedDate', currentDate);
    // } else if (!isNaN(storedIndex)) {
      
      // setCurrentIndex(storedIndex);
      
    // }
  // }, [dailyCount]);

  // useEffect(() => {
   
    // localStorage.setItem('currentIndex', currentIndex.toString());
  // }, [currentIndex]);

//   return (
//     <div>
//       <ul>
//         {dataList.map((item, index) => (
//           <div className={`message ${index === lastEnteredTextIndex ? 'last-entered-text' : ''}`} key={index}>
//             <span className="list-item-number">{currentIndex + index + 1}.</span>
//             <span className="list-item-text">{item.text}</span>
//             <span className="message-time">{item.time}</span>
            

           
//           </div>
          
//         ))}
// <div></div>
//       </ul>
   
    
//     </div>
//   );
// };

// export default ListView;
import React, { useEffect, useState } from 'react';

const ListView = ({ dataList, lastEnteredTextIndex, dailyCount,Totalcount2 }) => {
  const [currentIndex, setCurrentIndex] = useState(dailyCount);
  const [totalcountlist2, settotalcountlist2] = useState(Totalcount2)
  useEffect(() => {
    const storedIndex = parseInt(localStorage.getItem('currentIndex'));
    const storedDate = localStorage.getItem('storedDate');

    if (isNewDay()) {
      setCurrentIndex(0);
    } else if (!isNaN(storedIndex)) {
      setCurrentIndex(storedIndex);
    }
  }, [dailyCount]);

  useEffect(() => {
    if (isNewDay()) {
      localStorage.setItem('currentIndex', '0');
    } else {
      localStorage.setItem('currentIndex', currentIndex.toString());
    }
    localStorage.setItem('storedDate', new Date().toLocaleDateString());
  }, [currentIndex]);
useEffect(() => {
  // console.log("Totalcount",Totalcount2);/, 
}, [currentIndex])

  return (
    <div>
      <ul>
        {dataList.map((item, index) => (
          <div className={`message ${index === lastEnteredTextIndex ? 'last-entered-text' : ''}`} key={index}>
            <span className="list-item-number">{Totalcount2 + index + 1}.</span>
            <span className="list-item-text">{item.text}</span>
            <span className="message-time">{item.time}</span>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default ListView;

function isNewDay() {
  const storedDate = localStorage.getItem('storedDate');
  const currentDate = new Date().toLocaleDateString();
  return storedDate !== currentDate;
}

