import React from "react";
import "./welcome.css"
function Welcome ({onStartClick,message}){
    return(
        <div className="welcome"><h2></h2>
<p>
Jai Sri Ram! On this important occasion when Lord Ram is being consecrated in Ayodhya, let's come together and chant the 'Shri Ram' mantra to seek his blessings. Repeat 'Shri Ram Jai Ram Jai Jai Ram' as much as you can, and don't forget to use BookSeva to keep count. Chant, Share and be blessed. Jai Sri Ram.</p>      
<h1>श्री राम, जय राम, जय जय राम|</h1>
  <button onClick={onStartClick}><b>Start</b></button>
        </div>
    )
}
export default Welcome;