import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ListView from './Components/Listview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircle, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { faVolumeUp, faVolumeMute} from '@fortawesome/free-solid-svg-icons';
import audio from "./Components/audio/audio2.mp3";
import { v4 as uuidv4 } from 'uuid';
import { BaseUrl } from './url/config';
import logo from './Components/images/ohm.png';
import Welcome from './Components/welcome';
import Modes from './Components/Modes';
import { faMicrophone, faStopCircle,faKeyboard,faEarListen,faHome} from '@fortawesome/free-solid-svg-icons';
import share from './Components/images/share.png';
import WhatsApp from './Components/images/whatsAppicon.png';
import Facebook from './Components/images/facebookicon.png';
import Instagram from './Components/images/instagramicon.png';
import Gmail from './Components/images/gmailicon.png';
import annyang from 'annyang';
import Cookies from 'js-cookie'; 
import Dashboard from './Components/Dashboard';
import { appVersion } from './url/config';
import mode from './Components/images/mode.png';
import mode3 from './Components/images/mode3.png';
import mode4 from './Components/images/mode4.png';
import NoSleep from 'nosleep.js';
import stringSimilarity from 'string-similarity';
import { isIOS } from 'react-device-detect';
import Dictaphone from './Components/Speachrecognistion';

function App() {
  const [dataList, setDataList] = useState([]);
  const [text, setText] = useState('');
  const [showList, setShowList] = useState(true);
  const listContainerRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [textCount, setTextCount] = useState(0);
  const [totalTextCount, setTotalTextCount] = useState(0);
  const [firstEnteredText, setFirstEnteredText] = useState('');
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [uuid, setUuid] = useState('');
  const [isRegistered, setIsRegistered] = useState(localStorage.getItem('isRegistered') === 'true' || false);
  const [wrongTextError, setWrongTextError] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(true);
  const audioRef = useRef(null);
  const [showWelcomePopup, setShowWelcomePopup] = useState(true);
  const [showMode, setshowMode] = useState(false)
  const [isTyping, setIsTyping] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showToolTip2, setshowToolTip2] = useState(false);
  const [showToolTip3, setshowToolTip3] = useState(false);
  const [showToolTip4, setshowToolTip4] = useState(false);
  const [Shareoptions, setShareoptions] = useState(false);
  const [lastEnteredTextIndex, setLastEnteredTextIndex] = useState(-1);
  const [recognizedText, setRecognizedText] = useState('');
  const [isListening, setIsListening] = useState(false);
  const inputRef = useRef(null);
  const [isAutoMode, setIsAutoMode] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [speakemode, setspeakemode] = useState(false);
  const [selectedMode, setSelectedMode] = useState();
  const [showMessagePopup2, setshowMessagePopup2] = useState(false)
  const dataListRef = useRef(dataList);
  const [showtypetool, setshowtypetool] = useState(false)
  const [showmiketooltip, setshowmiketooltip] = useState(false)
  const [iseditmode, setiseditmode] = useState(true)
  // const [isListening, setIsListening] = useState(false);
  const [time, settime] = useState('')
const [Message, setMessage] = useState('')
const [totalcountlist, settotalcountlist] = useState('')
// const [localtextcount, setlocaltextcount] = useState('')
const [textcount2, settextcount2] = useState(
  localStorage.getItem('textcount2') || 0
);

const [textcount3, settextcount3] = useState('')
  
  const handleChangeFocus = () => {
    if (isAutoMode) {
      dataList.current.focus();
    }
  };
  
  useEffect(() => {
    if (isAutoMode) {
      const noSleep = new NoSleep();
  
      noSleep.enable();
  
      return () => {
        noSleep.disable();
      };
    }
  }, [isAutoMode]);


  
  useEffect(() => {
    // Check if the stored date is the same as the current date
    const storedDate = localStorage.getItem('resetDate');
    const currentDate = new Date().toLocaleDateString();

    if (storedDate !== currentDate) {
      // Reset dailyCount to 0 for a new day
      setDailyCount(0);
      localStorage.setItem('dailyCount', '0');
      localStorage.setItem('resetDate', currentDate);
    }
  }, []);


  

// const handleshareoptions = () =>{
//   setShareoptions(!Shareoptions)
// }
const handleMouseEnter = () => {
  setShowTooltip(true);
};

const handleMouseLeave = () => {
  setShowTooltip(false);
};

const handleMouseEnter2 = () =>{
  setshowToolTip2(true)
};

const handleMouseLeave2 = () =>{
   setshowToolTip2(false)
}
const handleMouseEnter3 = () => {
  setshowToolTip3(true);
};

const handleMouseLeave3 = () => {
  setshowToolTip3(false);
};
const handleMouseEnter4 = () => {
  setshowToolTip4(true);
};

const handleMouseLeave4 = () => {
  setshowToolTip4(false);
}; 
const handleInputChange = (e) => {
   
  const inputValue = e.target.value;
  setText(inputValue);
  setIsTyping(!!inputValue.trim()); 
};
const handleSendClick = () => {
  setText('');
  setIsTyping(!isTyping);
  if (inputRef.current) {
    inputRef.current.focus();
  }
}; 

// const handleWhatsAppShare = () => {
//    const textToShare = 'I am participating in the Naamajapa Yajna! Join me!';
//   const urlToShare = 'https://om.bookseva.com/' 
//   const whatsappShareURL = `https://wa.me/?text=${encodeURIComponent(
//     ${textToShare} ${urlToShare}
//   )}`;
// window.open(whatsappShareURL, '_blank');
// };
// const handleInstagramShare = () => {
//     const instagramShareURL = 'https://www.instagram.com/';
//     window.open(instagramShareURL, '_blank');
//   };
// const handleGmailShare = () => {
//     const subject = 'Join Naamajapa Yajna';
//     const body = 'I am participating in the Naamajapa Yajna! Join me at https://om.bookseva.com/';
//     const gmailShareURL = `mailto:?subject=${encodeURIComponent(
//       subject
//     )}&body=${encodeURIComponent(body)}`;
//     window.location.href = gmailShareURL;
// };
// const handleFacebookShare = () => {
//     const textToShare = 'I am participating in the Naamajapa Yajna! Join me!';
//     const urlToShare = 'https://om.bookseva.com/';
//     const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
//       urlToShare
//     )}&quote=${encodeURIComponent(textToShare)}`;

//     window.open(facebookShareURL, '_blank');
// };
const handleStartClick = () => {
    setShowWelcomePopup(false);
    toggleAudio(true)
    handleMode2()
    
    // setshowMode(true)
    
  };

  const handleRegistration = async () => {
    
    
    // let generatedUuid = localStorage.getItem('deviceUuid');
    // let generatedUuid = isIOS ? sessionStorage.getItem('deviceUuid') : localStorage.getItem('deviceUuid');

    // if (!generatedUuid) {
      // setShowWelcomePopup(true);
      // generatedUuid = uuidv4();
      // localStorage.setItem('deviceUuid', generatedUuid);
      // if (isIOS) {
        // sessionStorage.setItem('deviceUuid', generatedUuid);
      // } else {
        // localStorage.setItem('deviceUuid', generatedUuid);
      // }
  // }
  let generatedUuid = localStorage.getItem('deviceUuid');
 
  if (!generatedUuid) {
    setShowWelcomePopup(true);
    generatedUuid = uuidv4();
    localStorage.setItem('deviceUuid', generatedUuid);
}

  try {
      const response = await axios.post(BaseUrl +'/register', {
        uuid: generatedUuid,
      });
  
      if (response.status === 200) {
        setIsRegistered(true);
        localStorage.setItem('isRegistered', 'true');
        setUuid(generatedUuid);
        
        setIsRegistered(true);
   
        setRegistrationStatus('Registration Successful');
        
      } else if (response.status === 200  ) {
        setIsRegistered(false);
        localStorage.setItem('isRegistered', 'true');
        setRegistrationStatus('User Already Exists');
        setIsRegistered(true);
        setUuid(generatedUuid);
       
    
      } else {
        setIsRegistered(false);
        localStorage.setItem('isRegistered', 'false');
        setRegistrationStatus('Registration Failed');
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setRegistrationStatus('Registration Failed');
    }
  };

  useEffect(() => {
    axios.get(BaseUrl + '/Message')
      .then((response) => {
        const fetchedMessage = response.data.Message;
        setMessage(response.data.Message);
        settime(response.data.Time);
        // setlocaltextcount(response.data.TextCountLocal)
   
        
      })
      .catch((error) => {
        console.log("message error", Message);
      });
  }, [Message]);
   

  

  useEffect(() => {
    handleRegistration();

   showMessage();
 }, [uuid]);

 useEffect(() => {

  if (isRegistered && uuid) { 

    axios
.get(BaseUrl + '/textCount/' + uuid) 
.then((response) => {

  const fetchedTextCount = response.data.textCount;
  // console.log("response data",response.data.textCount);
  setTextCount(fetchedTextCount);
  settotalcountlist(fetchedTextCount)
 
})
.catch((error) => {

  console.error('Error fetching textCount:', error);
});
  }

}, [isRegistered, uuid]);
useEffect(() => {
  // console.log("textCount",textCount);
}, [textCount])

useEffect(() => {
  setTotalTextCount(totalTextCount+1)
}, [dataList])

useEffect(() => {
  axios
    .get(BaseUrl+'/totalTextCount')
    .then((response) => {
      const fetchedTotalTextCount = response.data.totalTextCount;
      setTotalTextCount(fetchedTotalTextCount);
  
      
    })
    .catch((error) => {    
      // console.error('Error fetching totalTextCount:', error);
    });

  if (listContainerRef.current) {
    listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight;
  }

  const intervalId = setInterval(() => {
    // setCurrentDate(new Date());
  }, 1000);

  return () => {
    clearInterval(intervalId);
  };
}, [speakemode,isAutoMode]);
useEffect(() => {
  if (listContainerRef.current) {
    listContainerRef.current.scrollTop = listContainerRef.current.scrollHeight;
  }
}, [dataList]);
useEffect(() => {
 
  if(!speakemode){
    stopListening();

 
  }

}, [showMode])
useEffect(()=>{
  // if(textcount2==0){
    setShowWelcomePopup(true);
    // setshowMode(true)
    setiseditmode(true)
  // }
},[uuid])

const toggleAudio = () => {
  let interval;
  const audioElement = audioRef.current;
  if (audioElement) {
    if (audioElement.paused) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
    setAudioPlaying(!audioElement.paused);
    
  }
  if(isAutoMode){
    // setIsAutoMode(false)
    // setshowMode(true)
    clearInterval(interval);
  }
};
const addData = (text) => {
  // console.log("text count adddata",textCount);
  if (!isRegistered) {
    

   
    return;
  }
  
  text = text.trim().toUpperCase();



if (text.toLowerCase() !== Message.toLowerCase()) {
  setWrongTextError(true);
  setTimeout(() => {
    setWrongTextError(false);
    // setIsTyping(false)
    setText(text.toLowerCase())
  }, 1500);
  return;
}

 if(Message.toLowerCase() ==''){
  return
 }
// setWrongTextError(false);
setText('');
// setIsTyping(false);
  // setCurrentNumber(currentNumber + 1);
   setRecognizedText('');
   
   setshowMode(false);
   const updatedDailyCount = parseInt(dailyCount) + 1;
   
   setDailyCount(updatedDailyCount);
  //  Cookies.set('dailyCount', updatedDailyCount); 
   localStorage.setItem('dailyCount', updatedDailyCount.toString()); 
   setTextCount(textCount + 1);
  //  console.log("adddata checking");
   if (inputRef.current) {
    inputRef.current.focus();
  }
  // axios
  //   .get(BaseUrl+'/firstEnteredText')
  //   .then((response) => {
      // const firstEnteredText = response.data.firstEnteredText;
      const firstEnteredText = Message;
      if (!firstEnteredText) {
        setFirstEnteredText(text);
     
        setDataList([{ text, time: getCurrentTime() }]);
        
        setShowList(true);
        
     
        toggleAudio();
      } else if (firstEnteredText.toLowerCase() === text.toLowerCase()) {
        const generatedUuid = localStorage.getItem('deviceUuid');
        // axios
        //   .post(BaseUrl+'/messages', { text: text, uuid: generatedUuid })
        //   .then((response) => {
        //     console.log('Message sent to the server');
        //   })
        //   .catch((error) => {
        //     console.error('Error sending message:', error);
        //   });

        const newDataList = [...dataListRef.current, { text, time: getCurrentTime() }];
        setDataList(newDataList);
        
        
        // settextcount3(textcount2+1)
      
        

        setLastEnteredTextIndex(newDataList.length - 1);
        const newIndex = newDataList.length - 1;
        setLastEnteredTextIndex(newIndex);
        setDailyCount(dailyCount+1)
      }
    // }
    // )
    // .catch((error) => {
    //   console.error('Error fetching firstEnteredText:', error);
    // });
};

useEffect(() => {
//  console.log("textcount useefffec",textCount);
}, [textCount])


const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    addData(text);
    setText('');
  } else if (e.key === 'Backspace') {
   
    setRecognizedText('');
  } else {
    if (!/^[a-zA-Z ]$/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    } else if (e.key === ' ' && text.endsWith(' ')) {
      e.preventDefault();
    }
  }
};

const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`; 
};


const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

useEffect(() => {
  if(dailyCount===0){
    setShowWelcomePopup(true);
    
  }

  return () => {
    
  }
}, [])

useEffect(() => {
  const storedTextcount2 = localStorage.getItem('textcount2');
  if (storedTextcount2) {
    settextcount2(parseInt(storedTextcount2));
  }
}, []);

useEffect(() => {
  const storedDailyCount = getDailyCount();
  setDailyCount(storedDailyCount);
}, []);

useEffect(() => {
const timer = setTimeout(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, 100);

  return () => clearTimeout(timer);
}, []);
dataListRef.current = dataList;

useEffect(() => {
  const handlePaste = (e) => {
    e.preventDefault(); 
};
const inputElement = document.getElementById('1');
inputElement.addEventListener('paste', handlePaste);

return () => {
    inputElement.removeEventListener('paste', handlePaste);
  };
}, []);

const sendTextCountToBackend = (uuid, textCount) => {
// console.log("setting textcount to backend",textCount);
localStorage.setItem('textcount2', textCount);
    axios
      .post(BaseUrl+'/updateTextCount', { uuid, textCount })
      .then((response) => {
      
        console.log('TextCount updated successfully.',textCount);
      })
      .catch((error) => {
        console.error('Error updating TextCount:', error);
      });
};


// useEffect(() => {
//   localStorage.setItem('textcount2', textcount2);
// }, [textcount2]);

// useEffect(() => {
//   localStorage.setItem(localtextcount, textcount2);
// }, [localtextcount, textcount2]);

// useEffect(() => {
//   if (isIOS) {
//     sessionStorage.setItem('textcount2', textcount2);
//   } else {
//    localStorage.setItem('textcount2', textcount2);
//   }
// }, [textcount2]);


const showMessage = () => {
  setshowMessagePopup2(true);
      setTimeout(() => {
     setshowMessagePopup2(false);
   }, 3500);
};

const showmike=()=>{
  setshowmiketooltip(true);
  setTimeout(() => {
  setshowmiketooltip(false) 
  }, 3500);
};

const showtype=()=>{
  setshowtypetool(true);
  setTimeout(() => {
  setshowtypetool(false) 
  },3500);
};

useEffect(() => {
    let shouldSendTextCount = true;
  
    const handleBeforeUnload = (e) => {
      if (shouldSendTextCount) {
        e.preventDefault();
        sendTextCountToBackend(uuid, textCount);
        shouldSendTextCount = false;
    
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      
    };
}, [uuid, textCount]);

// useEffect(() => {
//   const audioElement = audioRef.current;
//   if(showm){
//     setAudioPlaying(audioElement.play());
//   }

// })

useEffect(() => {
  let interval;
  const audioElement = audioRef.current;
  if (isAutoMode) {
    interval = setInterval(() => {
     
      if (!audioElement.paused) {
        addom();
      }
    }, time); 
    
  
  }
  else{

   
  }

  return () => {
    
    audioElement.removeEventListener('pause', () => {
      clearInterval(interval);
    });
    clearInterval(interval);
  };
}, [textCount,isAutoMode]);


const addom = () => {
  if(Message==''){
   
    return
  }
  const generatedText = Message;
  const currentTime = getCurrentTime();
  addData(generatedText);
 
};
useEffect(() => {
   
    const storedMode = localStorage.getItem('selectedMode');

    if (storedMode) {
     setSelectedMode(storedMode);
     
   
     switch (storedMode) {
        case 'mode1':
          // handleMode1();
          setshowMode(true);
          setiseditmode(true)
          break;
        case 'mode2':
          
          // handleMode2();
          setshowMode(true);
          setiseditmode(true)
      
          
          break;
        case 'mode3':
          // handleMode3();
          setiseditmode(true)
          setshowMode(true);
          break;
        default:
         
          break;
      }
    }else {
     
      setshowMode(true);
      setiseditmode(true)
      
    }
}, [isRegistered]);

const toggleMode = () => {
  setshowMode(true)
  setiseditmode(true)
  setIsAutoMode(false)
  setspeakemode(false)
  setshowtypetool(false)
  
  };
// const [dailyCount, setDailyCount] = useState(
//   Cookies.get('dailyCount') || 0
// );
const [dailyCount, setDailyCount] = useState(
  localStorage.getItem('dailyCount') || 0
);
const resetDailyCount = () => {
  const now = new Date();
  if (now.getHours() === 0 && now.getMinutes() === 0 && now.getSeconds() === 0) {
    setDailyCount(0);
    Cookies.set('dailyCount', 0);
  }
};

const startListening = () => {
  if (annyang) {
    annyang.setLanguage('en-IN');
    annyang.start({ autoRestart: true });
    setIsListening(true);

    
    annyang.addCallback('result', (phrases) => {
      const recognizedPhrase = phrases[0];
      setRecognizedText(recognizedPhrase);
      if (recognizedPhrase.toUpperCase() === Message) {
        setWrongTextError(false);
      } else {
        setWrongTextError(true);
      }
      
      addData(recognizedPhrase);
    });
  }
};

const stopListening = () => {

  if (annyang) {
   
    annyang.removeCallback('result');/* newly added didnt update yet or checked*/
    annyang.abort();
    setIsListening(false);
  }
};

// const toggleListening = () => {
//   const audioElement = audioRef.current;
//   if (audioElement) {
//     if (audioElement.played) {
//       audioElement.pause();
      
//     }   
//   }
//   if (annyang) {
//     if (annyang.isListening()) {
//       annyang.abort();
//       setIsListening(false);
//       stopListening();
//     } else {
//       annyang.start({ autoRestart: true });
//       setIsListening(true);

      
//       annyang.addCallback('result', (phrases) => {
//         const recognizedPhrase = phrases[0];
//   
//         setRecognizedText(recognizedPhrase);
//         if (recognizedPhrase.toUpperCase() === 'OM NAMO NARAYANAYA') {
//           setWrongTextError(false);
//         } else {
//           setWrongTextError(true);
//         }
        
//         addData(recognizedPhrase);
        
//     
//       });
//     }
//   }
// };
// useEffect(() => {
//   const startListening = () => {
//     if (annyang) {
//       annyang.setLanguage('en-IN');
//       annyang.start({ autoRestart: true });
//       setIsListening(true);
  
      
//       annyang.addCallback('result', (phrases) => {
//         const recognizedPhrase = phrases[0];
//         setRecognizedText(recognizedPhrase);
//         if (recognizedPhrase.toUpperCase() === 'OM NAMO NARAYANAYA') {
//           setWrongTextError(false);
//         } else {
//           setWrongTextError(true);
//         }
        
//         addData(recognizedPhrase);
//       });
//     }
//   };

//   const stopListening = () => {
//
//     if (annyang) {
//      
//       annyang.removeCallback('result');/* newly added didnt update yet or checked*/
//       annyang.abort();
//       setIsListening(false);
//     }
//   };

//   if (isIOS) {
//     if (window.SpeechRecognition || window.webkitSpeechRecognition) {
//       const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//       const recognition = new SpeechRecognition();

//       recognition.continuous = true;

//       recognition.onstart = () => {
//         setIsListening(true);
//       };

//       recognition.onend = () => {
//         setIsListening(false);
//       };

//       recognition.onresult = (event) => {
//         const result = event.results[event.resultIndex][0].transcript;
//         setText(result);
//       };

//       const startListeningForiOS = () => {
//         recognition.start();
//       };

//       const stopListeningForiOS = () => {
//         recognition.stop();
//       };

//       setIsListening(isListening);
//       stopListening(stopListeningForiOS);

//       return () => {
//         stopListeningForiOS();
//       };
//     } else {
//       // SpeechRecognition API not supported
//       console.log('SpeechRecognition is not supported in this browser.');
//     }
//   } else {
//    toggleListening()
//   }
// }, []);

const toggleListening = () => {
  const audioElement = audioRef.current;
  if (audioElement) {
    if (audioElement.played) {
      audioElement.pause();
    }
  }
  if (annyang) {
    if (annyang.isListening()) {
      annyang.abort();
      setIsListening(false);
      stopListening();
    } else {
      annyang.start({ autoRestart: true,pause:10 });
      
      setIsListening(true);

      annyang.addCallback('result', (phrases) => {
        const recognizedPhrase = phrases[0];
      

        // Correct the recognized phrase
        const correctedPhrase = correctRecognizedPhrase(recognizedPhrase);

        setRecognizedText(correctedPhrase);
        if (correctedPhrase.toUpperCase() === Message.toUpperCase()) {
         
          setWrongTextError(false);
        } else {
          
          setWrongTextError(true);
        }

        addData(correctedPhrase);

      
      });
    }
  }
};

// Function to correct the recognized phrase
const correctRecognizedPhrase = (phrase) => {
  const targetPhrase = Message;
  const similarities = stringSimilarity.findBestMatch(targetPhrase, [phrase]);
  const bestMatch = similarities.bestMatch;

  if (bestMatch.rating >= 0.2) {
   
    return targetPhrase;
  } else {
   
    return phrase;
  }
};

const incrementDailyCount = () => {
  const newDailyCount = parseInt(Cookies.get('dailyCount') || 0) + 1;
  Cookies.set('dailyCount', newDailyCount.toString(), { expires: 1 }); 
  
  // localStorage.setItem('dailyCount', newDailyCount.toString()); 
  setDailyCount(newDailyCount);
  
};
// const getDailyCount = () => {
//   const cookieCount = parseInt(Cookies.get('dailyCount') || 0);
//   const localCount = parseInt(localStorage.getItem('dailyCount') || 0);
//   return Math.max(cookieCount, localCount);
  
// };

// const getDailyCount = () => {
//   return parseInt(Cookies.get('dailyCount') || 0);
// };



const getDailyCount = () => {
  return parseInt(localStorage.getItem('dailyCount') || 0);
};
// const handleerror = () => {
//   const lowercaseText = text.toLowerCase();
//   const lowercaseMessage = Message.toLowerCase();

//   if (lowercaseText !== lowercaseMessage) {
   
//     setshowtypetool(true);
//   } else {
//     setshowtypetool(false);
   
//   }
// };
const handleerror = () => {
  const lowercaseText = text.toLowerCase();
  const lowercaseMessage = Message.toLowerCase();

  // Assuming both text and Message are strings of the same length
  const textArray = lowercaseText.split('');
  const messageArray = lowercaseMessage.split('');

  const isMismatch = textArray.some((letter, index) => letter !== messageArray[index]);

  if (isMismatch) {
    setshowtypetool(true);
  } else {
    setshowtypetool(false);
  }
};

useEffect(() => {
  handleerror();
}, [text]);

const handleMode1=()=>{
    setshowMode(false);
    setIsTyping(true);
    setiseditmode(false)
    showtype();
    // showMessage();
    setSelectedMode('mode1');
    const audioElement = audioRef.current;
    setAudioPlaying(audioElement.play());
    localStorage.setItem('selectedMode', 'mode1');
    localStorage.removeItem('selectedMode2');
    localStorage.removeItem('selectedMode3');
  }
const handleMode2=()=>{
    setshowtypetool(false)
    setshowMode(false);
    setspeakemode(false);
    setiseditmode(false)

    setIsAutoMode(true);
    // handleChangeFocus();
    setshowMessagePopup2(false);
  
    // showMessage();
    const audioElement = audioRef.current;
    setAudioPlaying(audioElement.play());
    setSelectedMode('mode2');
    localStorage.setItem('selectedMode', 'mode2');
    localStorage.removeItem('selectedMode1');
    localStorage.removeItem('selectedMode3');
  }

const handleMode3=()=>{
    showmike();
    setshowMode(false);
    setIsTyping(false);
    setspeakemode(true);
    setiseditmode(false)

    // showMessage();
    setSelectedMode('mode3');
    const audioElement = audioRef.current;
    setAudioPlaying(audioElement.pause());
    localStorage.setItem('selectedMode', 'mode3');
    localStorage.removeItem('selectedMode1');
    localStorage.removeItem('selectedMode2');
  }

  useEffect(() => {
    axios
  .get(BaseUrl + '/latestVersion')
  .then((response) => {
   
    if (response.status !== 200) {
      throw new Error('Received non-200 status code');
    }
    return response.data;
  })
  .then((data) => {
    
    const latestVersion = data.latestVersion;
   
    if (appVersion !== latestVersion) {
      // window.location.reload(true);
      
    }
  })
  .catch((error) => {
    console.error('Error checking latest version:', error);
   
  });

  }, []);



// useEffect(() => {
  
//   if (isRegistered) {
    
//     handleStartClick();
//   }
// }, [isRegistered]);

// const toggleAudio = () => {
//   const audioElement = audioRef.current;
  
//   if (isAutoMode) {
//     // When isAutoMode is true, always play the audio
//     if (audioElement && audioElement.paused) {
//       audioElement.play();
//     }
//      audioElement.muted = false;
//     setAudioPlaying(true);
//   } else {
//     // When isAutoMode is false, toggle audio on/off
//     if (audioElement) {
//       if (audioElement.paused) {
//         audioElement.play();
//       } else {
//         audioElement.pause();
//       }
//       setAudioPlaying(!audioElement.paused);
//     }
//   }
// };

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Naamajapa Yajna',
          text: 'Join me in the Naamajapa Yajna!',
          url: 'https://ram.bookseva.com/',
          
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
     
    }
  };
// Update the progress bar based on textcount2 and totalTextCount
const playAudio = () => {
  // Play audio logic
  if (audioRef.current) {
    audioRef.current.play();
  }
};

const stopAudio = () => {
  // Stop audio logic
  if (audioRef.current) {
    audioRef.current.pause();
  }
};
// useEffect(() => {
//   const percentage = (totalTextCount / 10000) * 100;
//   document.documentElement.style.setProperty('--percentage', `${percentage}%`); // Use template literals

// }, [totalTextCount]);
const nextTargetCount = Math.ceil(totalTextCount / 1008) * 1008;
useEffect(() => {
  // Calculate the next target count based on the current totalTextCount
  const nextTargetCount = Math.ceil(totalTextCount / 1008) * 1008;

  // Update the CSS variable
  document.documentElement.style.setProperty('--percentage', `${(totalTextCount / nextTargetCount) * 100}%`);

}, [totalTextCount]);

// const percentage = ( totalTextCount/3500) * 100;
// document.documentElement.style.setProperty('--percentage', ${percentage}%);
const handleVisibilityChange = () => {
  if (document.hidden) {
    // stopAudio();
    // setIsListening(false);
    // setshowMode(true);
    // setiseditmode(true)
    // setIsAutoMode(false);
    
  }
};

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  document.addEventListener('touchmove', function(event) {
    event.preventDefault();
  });
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
  });
  
  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.addEventListener('input', handleInput);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('input', handleInput);
      }
    };
  }, []);

  const handleInput = (e) => {
    if (e.inputType === 'insertFromPaste') {
      // Clear the input value when pasting is detected
      setText('');
    } else {
      // Handle other input events as needed
      setText(e.target.value);
    }
  };
return (
  
    <div className="App">
       {showWelcomePopup && <Welcome onStartClick={handleStartClick} message={Message} />}
      

      <div className="board">
      {/* <a href="https://bookseva.com/devotee-home-page">
        <img src={logo} alt='logo' className='logo'/>
        </a>
        <a href="https://bookseva.com/devotee-home-page">
        <div className='heading'> <span className='first'>NAAMAJAPA</span><span className='second'>YAJNA</span></div>
        <div className='heading2'> <span className='first'>നാമജപ</span><span className='second'>യജ്ഞം</span></div>
        </a> */}
        <a href="https://bookseva.com/devotee-home-page">
    <img src={logo} alt='logo' className='logo'/>
</a>
<a href="https://bookseva.com/devotee-home-page">
    <div class='heading'>
        <span class='first'>NAAMAJAPA</span>
        <span class='second'>YAJNA</span>
    </div>
    <div class='heading2'>
        <span class='first'>नामजप </span>
        <span class='second'> यज्ञा</span>
    </div>
</a>

        <button ><img src={share} alt="Share" className='logo2' 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleShareClick}
        /> {}
         {/* {showTooltip && <div className="tooltiptext">Click to share and invite your friends</div>} */}
        </button>
      </div>
<div className="current-date">
<div className="container">
<div className="row">
  <div className="col-2 order-1 text-left d-flex align-items-center justify-content-between">
    <div onClick={toggleAudio} className={showWelcomePopup ? 'speaker1' : 'speaker'}>
      <div className='sp'>
        <FontAwesomeIcon icon={audioPlaying ? faVolumeUp : faVolumeMute}
          onMouseEnter={handleMouseEnter3}
          onMouseLeave={handleMouseLeave3}
        />
      </div>
      {showToolTip3 && (
        <div className='tooltiptext3'>
          Click to {audioPlaying ? 'mute' : 'unmute'} audio
        </div>
      )}
    </div>
  </div>
  <div className="col-8 text-center order-2">
    {isToday(currentDate)
      ? ''
      : ``}
  </div>
  <div className='col-2 order-3 d-flex align-items-center justify-content-end'>
    <div>
      <button onClick={toggleMode} className={showWelcomePopup ? "mode1" : "mode"}>
        {isAutoMode ? (
          <div className='modename'>
            <div className='modename1'>Listening</div>
            <FontAwesomeIcon icon={faEarListen} className='modeicon' />
          </div>
        ) : speakemode ? (
          <div className='modename'>
            <div className='modename1'>Chanting</div>
            <FontAwesomeIcon icon={faMicrophone} className='modeicon' />
          </div>
        ) : showMode ? (
          <div className='modename'>
            <div className='modename1'>Modes</div>
            <FontAwesomeIcon icon={faHome} className='modeicon' />
          </div>
        ) : (
          <div className='modename'>
            <div className='modename1'>Writing</div>
            <FontAwesomeIcon icon={faKeyboard} className='modeicon' />
          </div>
        )}
      </button>
    </div>
  </div>
</div>

  <div className="message-popup-container" style={{ position: 'relative' }}>
  
  {showMessagePopup2 && !isAutoMode && !showWelcomePopup && (
    <div className="message-popup" style={{ top: '40px', left: '50%' }}>
      <div className="message-content">
        <p>Click here for toggle audio</p>
       
      </div>

    </div>
  )}
</div>

</div>

{showMode && !showWelcomePopup && (
  
 <Modes
 isIOS={isIOS}
 onmode1={handleMode1}
 onmode2={handleMode2}
 onmode3={handleMode3}
/>
)}
 
</div>
{showList && (
        <div className={showMode? "list-container1":"list-container"} ref={listContainerRef}>
          <ListView
          dataList={dataList}
          dailyCount={dailyCount} 
          lastEnteredTextIndex={lastEnteredTextIndex}
          Totalcount2 ={textcount2}
        />
          
        </div>
        )}
         
         {/* <div className={showWelcomePopup ? "count-text2" : "count-text"}>
  <Link to="/dashboard" className="custom-link"> 
    {textCount} out of {totalTextCount} Namajapas done by you.
  </Link>
</div> */}

 
<div className={showWelcomePopup ? "count-text2" : "count-text"}>
  {/* {!iseditmode &&(
<div className='hintt moveLeftToRight'>
    Shri Ram Jai Ram Jai Jai Jam
  </div>
  )} */}
<div className={isAutoMode?"meter2":"meter"}>
        <div className="progress"></div>
        <div className="number"></div>
      </div>
      
      <div className="wrapper">
        <div className="left-side">
          <span className="n1">{totalTextCount} Naamajapas</span>
          
        </div>
        <div className="right-side">
          <span className="n2">Next goal : {nextTargetCount} </span>
          
        </div>
        {showtypetool &&(
<div className='showtypetool'>Please Type <b>Shri ram jai ram jai jai ram</b></div>
)}
{showmiketooltip &&(
<div className='showmiketooltip'>Click here to start recording.</div>
)}
      </div>

  {/* <div >
    {textcount2} out of {totalTextCount} Naamajapas done by you.
  </div> */}
  {/* <div >
    {textcount2} Naamajapas done by you.
  </div> */}
  <div >
    {dailyCount} Naamajapas done by you Today.
  </div>
  
 
  {isAutoMode ? (
    <div>
  <div  className='hint moveLeftToRight'>
     {/* Be relaxed and listen to Holy Mantra. */}
     {Message} 
  </div>
  <div  className='hint2 moveLeftToRight'>
     {/* Be relaxed and listen to Holy Mantra. */}
     {Message} 
  </div>
  <div  className='hint3 moveLeftToRight'>
     {/* Be relaxed and listen to Holy Mantra. */}
     {Message} 
  </div>
  </div>
):iseditmode?(
  <div  className='hint moveLeftToRight'>
     Shri Ram Jai Ram Jai Jai Jam
  </div>
):isAutoMode ? (
  <div>
<div  className='hint moveLeftToRight'>
   {/* Be relaxed and listen to Holy Mantra. */}
   {Message} 
</div>
<div  className='hint2 moveLeftToRight'>
   {/* Be relaxed and listen to Holy Mantra. */}
   {Message} 
</div>
<div  className='hint3 moveLeftToRight'>
   {/* Be relaxed and listen to Holy Mantra. */}
   {Message} 
</div>
</div>
):''}
</div>




  <div className={isAutoMode? "data-entering-space8":"data-entering-space1"}>
  <div className={showWelcomePopup? "data-entering-space2":"data-entering-space"}>

    <div className={showWelcomePopup ? "input-container2":"input-container"}>
    {/* <span className="watermark ">Shri  ram  jai  ram  jai  jai  ram</span> */}
    {showMode ? (
          <span></span> // Render nothing when showmode is true
        ) : (
      <input
        type="text"
        placeholder={speakemode ? '' : `Type: ${Message}`}
        value={isTyping?text:recognizedText}
        onChange={(e) => {
          setText(e.target.value);
          setIsTyping(!!e.target.value.trim());
          handleerror()
        }}
        id="1"
        // onPaste={(e) => {
        //   e.preventDefault();
        // }}

        // onCopy={(e) => {
        //   e.preventDefault();
        // }}
        // onCut={(e) => {
        //   e.preventDefault();
        // }}
        onKeyDown={handleKeyPress}
        autoComplete="off"
        className={`input-field ${isTyping ? 'user-text' : 'recognized-text'}`}

        onMouseEnter={handleMouseEnter4}
        onMouseLeave={handleMouseLeave4}
        ref={inputRef}
        autoFocus={!isAutoMode}

        disabled={speakemode}
        // onPaste={(e) => e.preventDefault()} 
        onCopy={(e) => e.preventDefault()} 
        onCut={(e) => e.preventDefault()}
        onPaste={(e) => e.preventDefault()}

        
      />

      )}
      <input type="text" readOnly style={{ display: 'none' }} />

  
    {wrongTextError && (
  <div className="error-message">
    <div className="error-popup">
      <strong>Error:</strong> Please enter {Message}
    </div>
 
  </div> 
  
)}




    {showToolTip4 && <div className='tooltiptext4'>Type here to start NaamajapaYajna</div>}
  </div>
  
  
  {speakemode ? (
  
   <button
  className={showWelcomePopup ? 'btn3' : 'btn1'}
  type="button"
  onClick={toggleListening}
>
  <FontAwesomeIcon
    icon={isListening ? faStopCircle : faMicrophone}
    onMouseEnter={handleMouseEnter2}
    onMouseLeave={handleMouseLeave2}
  />
  {showToolTip2 && <div className="tooltiptext2">Toggle recording</div>}
  </button>
  
) : (
  showMode ? (
    <span></span> // Render nothing when showMode is true
  ) : (
  
  <button
    className='btn1'
    type="button"
    onClick={() => {
      addData(text);
      setText('');
    }}
  >
    <FontAwesomeIcon icon={faPaperPlane} className="btn4" type="button" 
   />
  
</button>

))}
</div>
  </div>

 


<audio id="audio" src={audio} preload="auto" ref={audioRef} loop />

{/* {Shareoptions&&(
 <div className="share-options">
  <div className="icon-box">
    <button className='card' onClick={handleWhatsAppShare}>
      <img src={WhatsApp} alt='btn5' className='btn5' />
    </button>
    <button className='card' onClick={handleFacebookShare}>
      <img src={Facebook} alt='btn6' className='btn6' />
    </button>
  </div>
  <div className="icon-box">
    <button className='card' onClick={handleInstagramShare}>
      <img src={Instagram} alt='btn7' className='btn7' />
    </button>
    <button className='card' onClick={handleGmailShare}>
      <img src={Gmail} alt='btn8' className='btn8' />
    </button>
  </div>
</div>
)} */}

      {/* {recognizedText && (
        <div>
          <strong>Recognized Text:</strong> {recognizedText}
        </div>
      )} */}
      {/* <button onClick={handleDateChange}>Simulate Date Change</button> */}

     </div>

  );
}
export default App;